import React from "react"
import Layout from "@/components/layout"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import logoElos from "@/images/lp/logo_bertoni_elos.svg"

const LeadsPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Leads">
        <div style={{ background: '#b2e5ff', textAlign: 'center' }}>
          <img src={logoElos} style={{ marginTop: 40 }} />
          <iframe src="https://forms.monday.com/forms/embed/b32a4d380b7007cf12269c3bab0b90d0?r=use1" width="100%" height="2000" style={{ border: 'none' }} />
        </div>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default LeadsPage;

